<script setup>
const visible = ref(false)
const props = defineProps({
  show: { type: Boolean, default: false },
  title: { type: String, required: true },
  description: { type: String, required: true },
  attribution: { type: String, default: undefined },
  link: { type: String, default: undefined },
  linkText: { type: String, default: undefined },
})
const emit = defineEmits(['hide-info'])

watch(() => props.show, () => {
  visible.value = props.show
})
onMounted(() => visible.value = props.show)
function onAfterModalHidden() {
  emit('hide-info')
  visible.value = false
}

const resumeNavHack = useNavHack()
function onVisit() {
  resumeNavHack.value = false
}
</script>

<template>
  <Teleport to="#teleports">
    <cv-modal
      id="comic-modal-info"
      :visible="visible"
      :disable-teleport="true"
      @after-modal-hidden="onAfterModalHidden"
    >
      <template #label>
        Comic information
      </template>
      <template #title>
        {{ title }}
      </template>
      <template #content>
        <p>
          {{
            description
          }}
        </p>
        <br>
        <p v-if="attribution">
          {{ attribution }}
        </p>
        <cv-link
          v-if="link && link.startsWith('/') && resumeNavHack"
          :href="link"
          @click="onVisit"
        >
          {{ linkText }}
        </cv-link>
        <cv-link
          v-else-if="link && link.startsWith('/')"
          :to="link"
          @click="onVisit"
        >
          {{ linkText }}
        </cv-link>
        <cv-link
          v-else-if="link"
          :href="link"
        >
          {{ linkText }}
        </cv-link>
      </template>
    </cv-modal>
  </Teleport>
</template>

<style scoped lang="css">

</style>
