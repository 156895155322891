<script setup>
import { useStorage } from '@vueuse/core'
import resumeCardImg from '@/assets/images/resume-card.jpg'

const cv = useStorage('resume', '/resume')
const role = useStorage('role', 'Full Stack Software Developer')

// Fake comic data for resume page
const resumeComic = {
  id: 'qUCTdx',
  description: 'I have built and implemented dozens of scalable web applications for IBM’s internal teams and client-facing solutions including Watson Health and WebSphere Portal. I have deployed new technologies, including AI, to drive automation, optimize performance, and improve efficiency.',
  title: role.value,
  thumbnail: {
    path: resumeCardImg,
  },
  link: cv.value,
  linkText: 'Résumé/CV',
  attribution: false,
}

// TODO: use status for errors
const { status: marvelStatus, data: marvel } = useLazyFetch('/api/randomComic')

// If Marvel fails try Superhero API
const { data: heroes, execute: heroExecute, status: heroStatus } = await useLazyFetch('/api/randomHero', {
  immediate: false,
})
watch(marvelStatus, () => {
  if (marvelStatus.value === 'error') {
    console.log('get alt comics marvelStatus', marvelStatus.value)
    setTimeout(heroExecute, 0)
  }
})

// Debug loading state with these
// const marvelStatus = ref('pending')
// onMounted(() => setTimeout(() => marvelStatus.value = 'done', 5000))

const altComics = computed(() => {
  try {
    if (heroStatus.value !== 'success') return []
    return heroes.value
  }
  catch (err) {
    console.warn('error getting super heroes', err.message)
    return []
  }
})
const comics = computed(() => {
  try {
    if (marvelStatus.value === 'pending') return []
    const list = marvel.value.data.results.slice(0, 3)
    list.push(resumeComic)
    return list
  }
  catch (err) {
    console.warn('error getting comics array', err.message)
    return [resumeComic]
  }
})
function description(comic) {
  try {
    const textObjects = comic.textObjects || []
    const issue_solicit_text = textObjects.find(item => item.type === 'issue_solicit_text')
    if (issue_solicit_text) return issue_solicit_text.text.replace(/<[^>]*>?/gm, '')
    if (comic.description) return comic.description
  }
  catch {
    // ignore error - return default
  }
  return `Marvel comic ${comic.id}`
}
function title(comic) {
  return comic.title || `Marvel comic ${comic.id}`
}
function comicImage(comic) {
  try {
    if (!comic.thumbnail.path.includes('image_not_available')) {
      if (comic.thumbnail.extension)
        return comic.thumbnail.path + '.' + comic.thumbnail.extension
      return comic.thumbnail.path
    }
  }
  catch {
    // ignore error - return default
  }
  return '/funny-book.jpeg'
}
function attribution(comic) {
  if (comic.attribution !== false)
    return marvel.value.attributionText
}

const show = ref(false)
const infoTitle = ref('')
const infoDescription = ref('')
const infoLink = ref(null)
const infoLinkText = ref(null)
const infoAttribution = ref(null)
function showInfo(comic) {
  infoTitle.value = title(comic)
  infoDescription.value = description(comic)
  infoAttribution.value = attribution(comic)
  infoLink.value = comic.link
  infoLinkText.value = comic.linkText
  show.value = true
}
function showHero(hero) {
  infoTitle.value = hero.name
  infoDescription.value = hero.connections ? hero.connections['group-affiliation'] : hero.name
  infoAttribution.value = 'Superhero API'
  infoLink.value = undefined
  infoLinkText.value = undefined
  show.value = true
}
</script>

<template>
  <div class="h-screen w-screen bg-[url('@/assets/images/comic-background-b.jpg')] bg-cover">
    <landing-info
      :show="show"
      :title="infoTitle"
      :description="infoDescription"
      :attribution="infoAttribution"
      :link="infoLink"
      :link-text="infoLinkText"
      @hide-info="show=false"
    />
    <div class="grid size-full grid-cols-2 grid-rows-2 gap-2 p-8 md:grid-cols-4 md:grid-rows-1 md:pb-2">
      <landing-loading-cards v-if="marvelStatus === 'pending'" />
      <landing-alt-card
        v-for="comic in altComics"
        :key="comic.id"
        :super-hero="comic"
        @show-info="showHero(comic)"
      />
      <landing-card
        v-for="comic in comics"
        :key="comic.id"
        :comic-image="comicImage(comic)"
        :title="title(comic)"
        @show-info="showInfo(comic)"
      />
    </div>
  </div>
</template>

<style scoped lang="css">
</style>
