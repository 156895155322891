<script setup>
Array(4)
</script>

<template>
  <div
    v-for="i in Array(4)"
    :key="i"
    class="flex flex-col justify-end"
  >
    <cv-tile
      class="h-56 !bg-carbon-gray-20/75 md:h-96 md:w-64"
    >
      <cv-skeleton-text />
    </cv-tile>
  </div>
</template>
